.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Century Gothic';
  src: url('./fonts/CenturyGothic.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MOLIKA';
  src: local('MOLIKA'), url('./fonts/MOLIKA.ttf') format('truetype');
  font-weight: bolder;
  font-style:normal;
}

.flip-container {
  perspective: 1000px;
}
.flip-container:hover .flipper, .flip-container.hover .flipper {
  transform: rotateY(180deg);
}

.flipper {
  transition: 0.6s;
  transform-style: preserve-3d;
  position: relative;
}
.front, .back {
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}
.front {
  z-index: 2;
  transform: rotateY(0deg);
}
.back {
  transform: rotateY(180deg);
}





/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 22px;
  height: 22px;
  left: 18px;
  top: 18px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #292b34;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #000000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #ffffff;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: white;
  padding: 3.8em 0.5em 0;
  font-size: 1.2em;
  color: #000000;
  font-family: 'montserrat';
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #000000;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  padding: 0.9em;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}


.MuiIcon-colorPrimary {
  color: #000000;
}
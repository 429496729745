body {
  margin: 0;
  background-color: white;
  font-family: 'Century Gothic', 'VoyageBold' ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.header {
  position: sticky;
  height: 8vh;
  top: 0vh;
  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 369ms;
}

.header.hide {
  top: -15vh;
}

.header1 {
  position: sticky;
  top: 9.5vh;
  transition-property: all; 
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 369ms;
}

.header1.hide {
  top: -10vh;
}





#testimonialsDesktop {
  text-align: center;
  padding: 20px;
  background-color: #f2e076;
  max-width: 65%;
  margin: 0 auto;
  align-items: center;
}
#testimonialsMobile {
  text-align: center;
  padding: 20px;
  background-color: #f2e076;
  max-width: 95%;
  margin: 0 auto;
  align-items: center;
}

.testimonialDesktop {
  margin: 20px;
  padding: 20px;
  background-color: #fff;
  color: #000;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  transition: all 0.2s ease-in-out;
}

.testimonialMobile {
  padding: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: #fff;
  color: #000;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
  transition: all 0.2s ease-in-out;
}

.testimonialMobile:hover {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transform: translateY(-3px);
}
.testimonialDesktop:hover {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transform: translateY(-3px);
}

.bm-burger-button:hover {
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transform: translateY(-3px);
}

.customer-name {
  font-style: italic;
  color: #555;
  margin-top: 10px;
  text-align: right;
}



@font-face {
  font-family: 'VoyageBold';
  src: local('VoyageBold'), url(./fonts/VoyageBold.ttf) format('truetype');
}